import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-637c8a13"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "need-help-wrapper"
};
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");

  const _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$tm('common.need_help.title')), 1), _createVNode(_component_router_link, {
    to: "/contact"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "help-button"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$tm('common.need_help.contact')), 1)]),
      _: 1
    })]),
    _: 1
  })]);
}